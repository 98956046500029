.avatar {
    display: inline-block;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
}
.avatar img {
    width: 100%;
    height: 100%;
}
